import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

export const Career = ({ trigger, setTrigger, career }) => {
  const [showApplyForm, setShowApplyForm] = useState(false); // State to toggle between job openings and apply form
  const [name, setName] = useState("");
  const [job, setJob] = useState("");
  const [email, setEmail] = useState("");
  const [file, setFile] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (trigger) {
      document.body.style.overflow = "hidden"; // Disable scrolling on the main page
    } else {
      document.body.style.overflow = ""; // Enable scrolling on the main page
    }

    // Reset the apply form view when closing the popup
    if (!trigger) {
      setShowApplyForm(false);
    }

    return () => {
      document.body.style.overflow = ""; // Ensure scrolling is enabled when the component unmounts
    };
  }, [trigger]);

  const validateForm = () => {
    let errors = {};

    // Validate name
    if (!/^[a-zA-Z\s.]+$/.test(name)) {
      errors.name = "Name can only contain alphabets, spaces, and dots.";
    }

    // Validate email
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = "Invalid email address.";
    }

    // Validate job
    if (!/^[a-zA-Z0-9\s()]+$/.test(job)) {
      errors.job =
        "Job can only contain alphanumeric characters, spaces, and parentheses.";
    }

    return errors;
  };

  const submitapply = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      const formData = new FormData();
      formData.append("job", job);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("file", file);

      const result = await axios.post(
        "http://configserverllp.com/api/uploadfiles",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (result.data.status === "okie") {
        Swal.fire({
          title: "Application Sent...",
          icon: "success",
        }).then(() => {
          setTrigger(false); // Close the popup after showing the success message
          setEmail("");
          setFile("");
          setJob("");
          setName("");
        });
      }
    } else {
      setErrors(errors);
    }
  };

  const toggleView = () => {
    setShowApplyForm(!showApplyForm);
    setErrors({});
  };

  return trigger ? (
    <>
      <div className="popup" id="popup">
        <div className="popupinner">
          <button className="close-btn" onClick={() => setTrigger(false)}>
            <i className="fa fa-times"></i>
          </button>
          <h2 style={{ textAlign: "center" }}>
            {showApplyForm ? "Apply for Job" : "Internal Job Openings"}
          </h2>
          {showApplyForm ? (
            <form onSubmit={submitapply}>
              <div className="form-group">
                <label htmlFor="email">Email address</label>
                <input
                  type="email"
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                  id="email"
                  placeholder="Enter Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="name">Name : </label>
                <input
                  type="text"
                  className={`form-control ${errors.name ? "is-invalid" : ""}`}
                  id="name"
                  placeholder="Enter Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                {errors.name && (
                  <div className="invalid-feedback">{errors.name}</div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="job">Jobtitle : </label>&nbsp;
                <select
                  id="job"
                  onChange={(e) => setJob(e.target.value)}
                  value={job}
                  className="select"
                  style={{ position: "absolute" }}
                  required
                >
                  <option value="">Select Jobtitle</option>
                  {career.map((item) => {
                    return (
                      <option value={item.jobtitle}>{item.jobtitle}</option>
                    );
                  })}
                </select>
                <span className="absolute top-1/2 right-4 z-30 -translate-y-1/2">
                  <svg
                    className="fill-current"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.8">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                        fill=""
                      ></path>
                    </g>
                  </svg>
                </span>
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputFile">Upload Resume: </label>&nbsp;
                <input
                  type="file"
                  id="exampleInputFile"
                  accept="application/pdf,application/vnd.ms-excel"
                  onChange={(e) => setFile(e.target.files[0])}
                  required
                />
              </div>
              <div className="btn-container">
                <button
                  type="submit"
                  className="btn-custom"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Apply
                </button>
                <button className="btn-back" onClick={toggleView}>
                  <i className="fa fa-arrow-left"></i>
                </button>
              </div>
            </form>
          ) : career && career.length > 0 ? (
            <div className="row">
              {career.map((item, i) => (
                <div key={`${item.name}-${i}`} className="col-4">
                  <div className="career">
                    <div className="title">
                      <h3 style={{ fontWeight: "800" }}>{item.jobtitle}</h3>{" "}
                    </div>
                    <div className="career-desc">
                      <p>
                        <b>Job profile: {item.jobprofile}</b>
                      </p>
                      <pre className="pre-tag">-{item.jobdescription}</pre>
                    </div>
                  </div>
                </div>
              ))}
              <hr />
              <div className="btn-container">
                <button className="btn-custom" onClick={toggleView}>
                  Go To Apply
                </button>
              </div>
            </div>
          ) : (
            <h4 style={{ textAlign: "center", margin: "55px" }}>
              No job openings at the moment.
            </h4>
          )}
        </div>
      </div>
    </>
  ) : (
    ""
  );
};
