// import React, { useEffect, useState } from "react";

// export const Features = ({ features }) => {
//   const [result, setResult] = useState(features);
//   const [start, setStart] = useState(0);
//   const [end, setEnd] = useState(4);

//   const checkNext = () => {
//     setResult(features.slice(start, end));
//   };

//   let status = Math.ceil(features.length / 4);

//   useEffect(() => {
//     checkNext();
//   }, [start]);

//   useEffect(() => {
//     checkNext();
//   }, [features]);

//   return (
//     <div id="features" class="container">
//       <div class="row">
//         <div class="col-xs-12 section-title">
//           <h2 class="text-center">Features</h2>
//         </div>
//       </div>
//       <div class="row">
//         {result ? (
//           result.map((item, i) => (
//             <div key={`${item.title}-${i}`} class="col-xs-12 col-sm-6 col-md-3">
//               <div class="thumbnail">
//                 <img
//                   style={{ height: "150px", borderRadius: "15px" }}
//                   src={require(`../images/${item.image}`)}
//                   alt={item.title}
//                 />
//                 <div class="caption">
//                   <h3>{item.title}</h3>
//                   {/* <p>{item.description.slice(0, 85)}...</p> */}
//                   <p>{item.description}</p>
//                 </div>
//               </div>
//             </div>
//           ))
//         ) : (
//           <div class="col-xs-12 text-center">Loading...</div>
//         )}
//       </div>
//     </div>
//   );
// };

import React, { useEffect, useState } from "react";

export const Features = ({ features }) => {
  const [result, setResult] = useState(features);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(4);

  const checkNext = () => {
    setResult(features.slice(start, end));
  };

  let status = Math.ceil(features.length / 4);

  useEffect(() => {
    checkNext();
  }, [start]);

  useEffect(() => {
    checkNext();
  }, [features]);

  return (
    <div id="features" className="container">
      <div className="row">
        <div className="col-xs-12 section-title">
          <h2 className="text-center">Features</h2>
        </div>
      </div>
      <div className="row">
        {result ? (
          result.map((item, i) => (
            <div
              key={`${item.title}-${i}`}
              className="col-xs-12 col-sm-6 col-md-3"
            >
              <div
                className="thumbnail"
                style={{ width: "100%", height: "350px", overflow: "hidden" }}
              >
                <img
                  style={{
                    height: "150px",
                    borderRadius: "15px",
                  }}
                  src={require(`../images/${item.image}`)}
                  alt={item.title}
                />
                <div className="caption">
                  <h3>{item.title}</h3>
                  <p>{item.description.slice(0, 190)}...</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-xs-12 text-center">Loading...</div>
        )}
      </div>
    </div>
  );
};
