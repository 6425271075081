import React from "react";

export const Services = ({ services }) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
            Transforming Ideas into Reality! From web design and software
            development to mobile apps, AI, and ethical hacking, we deliver
            cutting-edge solutions that empower businesses to thrive in the
            digital era. Experience innovation with us.
          </p>
        </div>

        <div className="container-fluid">
          <div className="row">
            {services ? (
              services.map((item, index) => (
                <div
                  key={index}
                  className="col-lg-4 col-md-4 col-sm-6"
                  style={{ marginBottom: "20px" }}
                >
                  <img
                    src={require(`../images/${item.image}`)}
                    alt={item.title}
                    className="image-responsive"
                    style={{
                      borderRadius: "15px",
                      width: "100%",
                      height: "250px",
                      objectFit: "cover",
                    }}
                  />
                  <div className="service-desc">
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-xs-12 text-center">loading</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
