import React, { useEffect, useState } from "react";
import parrow from "./parrow.png";
import narrow from "./narrow.png";

export const Team = ({ team }) => {
  const [result, setResult] = useState(team);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(4);
  const [page, setPage] = useState(1);

  let status = Math.ceil(team.length / 4);

  useEffect(() => {
    check();
    checkNext();
  }, [start]);
  useEffect(() => {
    check();
    checkNext();
  }, [team]);

  const Next = () => {
    setPage((previous) => previous + 1);
    setEnd((previous) => previous + 4);
    setStart((previous) => previous + 4);
  };

  const check = () => {
    setResult(team);
  };
  const checkNext = () => {
    setResult(team.slice(start, end));
  };

  const Previous = () => {
    setPage((previous) => previous - 1);
    setStart((previous) => previous - 4);
    setEnd((previous) => previous - 4);
  };
  return (
    <div id="team" className="text-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-8 section-title">
            <h2>Meet the Team</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center">
            {result
              ? result.map((item, i) => (
                  <div
                    key={`${item.name}-${i}`}
                    className="col-md-3 col-sm-6 team"
                  >
                    <div className="thumbnail">
                      <img
                        style={{
                          borderRadius: "40px",
                          height: "250px",
                          width: "250px",
                          objectFit: "cover",
                        }}
                        src={require(`../images/${item.image}`)}
                        height="100px"
                      />
                      <div className="caption">
                        <h4>{item.title}</h4>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
        <div className="col-4 d-flex justify-content-end align-items-center">
          <button
            disabled={page == 1}
            onClick={Previous}
            style={{
              fontSize: "60px",
              border: "none",
              background: "transparent",
              cursor: "pointer",
              marginRight: "20px",
            }}
          >
            <img src={parrow} alt="Previous" />
          </button>
          <button
            disabled={page + 1 > status}
            onClick={Next}
            style={{
              fontSize: "60px",
              border: "none",
              background: "transparent",
              cursor: "pointer",
            }}
          >
            <img src={narrow} alt="Next" />
          </button>
        </div>
      </div>
    </div>
  );
};
