import React from "react";

export const About = ({ aboutdata }) => {
  return (
    <div id="about">
      <h2 className="aboutback section-title">About Us</h2>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            {aboutdata ? (
              <img
                src={require(`../images/${aboutdata.image}`)}
                style={{ borderRadius: "15px" }}
                className="img-responsive"
                alt=""
              />
            ) : null}{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h3 style={{ textAlign: "center" }}>
                {aboutdata ? aboutdata.title1 : "none"}
              </h3>
              <p style={{ textAlign: "justify" }}>
                {aboutdata ? aboutdata.description1 : "none"}
              </p>
              <h3 style={{ textAlign: "center" }}>
                {aboutdata ? aboutdata.title2 : "none"}
              </h3>
              <p style={{ textAlign: "justify" }}>
                {aboutdata ? aboutdata.description2 : "none"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
