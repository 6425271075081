// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 16000px) and (min-width: 0px) {
  #features {
    width: 100%;
  }

  #about,
  #services,
  #testimonials,
  #team,
  #map,
  #contact,
  #footer,
  #header,
  #popup,
  #menu {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE;IACE,WAAW;EACb;;EAEA;;;;;;;;;;IAUE,WAAW;EACb;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":["@media only screen and (max-width: 16000px) and (min-width: 0px) {\n  #features {\n    width: 100%;\n  }\n\n  #about,\n  #services,\n  #testimonials,\n  #team,\n  #map,\n  #contact,\n  #footer,\n  #header,\n  #popup,\n  #menu {\n    width: 100%;\n  }\n\n  #portfolio {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
