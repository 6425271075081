import React, { useState,useRef } from "react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import axios from "axios";

export const Contact = () => {
  const form = useRef();
  const [from_name, setFrom_Name] = useState("");
  const [from_email, setFrom_Email] = useState("");
  const [message, setMessage] = useState("");
  const handleSubmit = async(e) => {
    e.preventDefault();
    const formData=new FormData(); 
    formData.append("from_name",from_name);
    formData.append("from_email",from_email);
    formData.append("message",message);
    console.log(formData);
    try{
      const result =await axios.post(
        "http://13.200.149.88:5000/addNotification",
        formData,{
        headers:{
  
          "Content-Type":"application/json"
        },
        }
      );
      if(result.data.status==="ok"){
  
        console.log("saved to database successfully..") 
      }
    }catch(error){
      console.error("Error sending form data:",error);
    }

    emailjs
      .sendForm("service_ifni80f", "template_drmzc3o", form.current, {
        publicKey: "4R4i1hnzldugIHfP4",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          Swal.fire({
            width: 400,
            padding: "3em",
            color: "#716add",
            title: "Messege Sent",
            icon: "success",
          }).then((result)=>{
            if(result.isConfirmed){
              window.location.reload();
            }
          })
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
           <form ref={form} onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="from_name"
                        className="form-control"
                        placeholder="Name"
                        onChange={(e)=> setFrom_Name(e.target.value)}
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        name="from_email"
                        className="form-control"
                        placeholder="Email"
                        onChange={(e)=> setFrom_Email(e.target.value)}
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    onChange={(e)=> setMessage(e.target.value)}
                    required
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <input
                  type="submit"
                  className="btn btn-custom btn-lg"
                  value="Send"
                />
              </form>
            </div>
          </div>


          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                Office No. 303, 313, A Wing, Laxmi Horizon, HDFC Bank, Mumbai
                Bangaluru Highway, Punawale, Pune - 411033, Maharashtra, India. 12345
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                <a href="tel:+919156486909" style={{ color: "white" }}>
                  +91 9156486909
                </a>
                <br></br>
                <a href="tel:+918605386909" style={{ color: "white" }}>
                  +91 8605386909
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                info@configserverllp.com
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  {/* <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li> */}
                  {/* <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="https://www.linkedin.com/company/config-server-llp/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          © Copyright <strong>Config Server LLP</strong>. All Rights Reserved
          <p>Designed by Config Server</p>
        </div>
      </div>
    </div>
  );
};
